/* 
    * ======================== *   
    * Define Vars *
    * ======================== *
*/
const MWidthMax = 989;
const currentWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
/* 
    * end/ Define Vars *
*/




/* 
    * ======================== *
    * Import _modernizr.js *
    * ======================== *
*/
require('./_modernizr.js');
/* 
    * end/ Import modernizr.js  *
*/




/* 
    * ======================== *
    * Imports Yarn packages using CommonJS modules import method  *
    * ======================== *
*/
const polyfillObserver = require('intersection-observer');
const $ = require('jquery');
const backstretch = require('jquery-backstretch');
const hammerjs = require('hammerjs');
const Flickity = require("flickity");
const L = require('leaflet');
const Konami = require("konami");
const WOW = require('wowjs');
const FastClick = require('fastclick');
const lozad = require('lozad');

// Enable BG Videos on screen > 989px (considering screens below are tablets and phones)
if(currentWindowWidth > MWidthMax) {
    const jarallax = require("jarallax/dist/jarallax.js");
    const jarallaxVideo = require("jarallax/dist/jarallax-video.js");
}
/*
    * end/ Imports Yarn packages *
*/




/*
    * ======================== *
    * Launch native javascript plugin *
    * ======================== *
*/
// Fastclick
FastClick.attach(document.body);

// Wow.js
window.wow = new WOW.WOW({
    live: false
});
window.wow.init();

// LazyLoad on images with Lozad.js
const imgObserver = lozad('[data-lazy]', {
    rootMargin: '250px 0px'
});
imgObserver.observe();
/*
    * end/ Launch native javascript plugin *
*/




/*
    * ======================== *
    * jQuery Plugins
    * ======================== *
*/
$(function () {

    // Konami
    if ($('.js-link-preston').length) {
        window.Konami = new Konami(function () {
            $('.js-link-preston .preston').addClass('hinge');

            setTimeout(function () {
                $('.js-link-preston').find('.doge').removeClass('hide').addClass('slideInUp');
            }, 4200);
        });
    }

    /*
        -- @ MAPS FOOTER @ --
        * Leaflet maps
    */
    // Launch Leaflet with lazy load
    const mapObserver = lozad('#leaflet-maps', {
        rootMargin: '250px 0px',
        loaded: function(el) {
            var mymap = L.map('leaflet-maps').setView([45.644660, 5.913401], 6);

            var OpenStreetMap_France = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
                maxZoom: 20,
                attribution: '&copy; Openstreetmap France | &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            });

            OpenStreetMap_France.addTo(mymap);
            mymap.scrollWheelZoom.disable();

            var customMarker = L.icon({
                iconUrl: '/images/marker-alpixel.png',
                shadowUrl: '/images/marker-alpixel-shadow.png',
                iconSize: [70, 61], // size of the icon
                shadowSize: [99, 61], // size of the shadow
                iconAnchor: [22, 62], // point of the icon which will correspond to marker's location
                shadowAnchor: [5, 60],  // the same for the shadow
                popupAnchor: [10, -55]
            });

            var marker = L.marker([45.644666, 5.913468], {icon: customMarker}).addTo(mymap).bindPopup("<p style='text-align:center;'><strong>ALPIXEL, agence web Savoie</strong><br />101 Rue Maurice Herzog<br />Bâtiment AGRION<br />73420 MÉRY<br /><br /> Tél. 04 79 54 20 66</p>");
        }
    });
    mapObserver.observe();

    
    /*
    -- @ LINKS @ --
    */
    // External links
    $(document).on('click', 'a[href$=".pdf"], a.external-link', function (e) {
        e.preventDefault()
        window.open($(this).attr("href"))
    })


    /*
        -- @ BACKSTRETCH @ --
        * Attach responsive background-images to elements
    */
    // Launch Backstretch with lazy load
    const backstretchObserver = lozad('.backstretch', {
        rootMargin: '50px 0px',
        loaded: function(el) {
            var imgName = $(el).attr('data-img');

            if (typeof imgName !== 'undefined' && imgName.length) {
                
                // If brower supports WebP img, so change image extension 8) \m/
                if(Modernizr.webp) {
                    imgName = imgName.substr(0, imgName.lastIndexOf(".")) + ".webp";
                }

                $(el).backstretch(imgName);
            }
        }
    });
    backstretchObserver.observe();


    /*
        -- @ MAIN MENU PHONE @ --
        * Show the hamburger button for main menu on phones
        */
    var _closeMenus = function () {
        $('html, body').removeClass('opened-menu')
        // Remove "shown" class to all menus
        $('#main-header nav').removeClass('shown')
        // Remove "is-active" class to all hamburger buttons
        $('.js-open-menu button').removeClass('is-active')
    }
    var _openMenu = function (menu, button) {
        $('html, body').addClass('opened-menu')
        menu.addClass('shown')
        button.addClass('is-active')
    }
    // Click on Hamburger
    $(document).on('click', '.js-open-menu button', function (e) {
        e.preventDefault()

        var $menu = $(this).parent().next()

        if (!$menu.is('nav')) {
            alert('<nav> element must be placed just after the hamburger (msg from app.js)')
            return;
        }

        if ($(this).hasClass('is-active')) {
            _closeMenus();
        } else {
            _openMenu($menu, $(this));
        }
    });

    // HammerJS
    if ($('#js-main-nav').length) {
        // Swipe left to close menus
        var navBottomHeader = document.getElementById('js-main-nav');
        var hammerBottomMenu = new Hammer(navBottomHeader);

        hammerBottomMenu.on('swipeleft', function (ev) {
            _closeMenus();
        });

        var logo = document.getElementById('logo');
        var hammerLogo = new Hammer(logo);

        // Swipe Right on logo = open main menu
        hammerLogo.on('swiperight', function (ev) {
            _openMenu($('#js-main-nav'), $('.js-open-menu button'));
        });
    }


    /*
     -- @ FLICKITY PLUGIN @ --
        * Used in:
        	- xxx
            */
    if ($('.main-carousel').length) {

        var elem = document.querySelector('.main-carousel');

        var flkty = new Flickity(elem, {
            wrapAround: true, // Infinite horizontal scroll ?

            // Player
            autoPlay: 20000, // Move each x miliseconds
            pauseAutoPlayOnHover: true, // Pause on hover ?

            // Slider animations
            selectedAttraction: 0.08, // Animation speed
            friction: 0.7,

            adaptiveHeight: true,

            // Arrows
            prevNextButtons: false,
            arrowShape: {
                x0: 10,
                x1: 60, y1: 50,
                x2: 65, y2: 45,
                x3: 20
            }
        });

        // When we click on arrow, it make the slider in pause. So, launch player after clicking
        flkty.on('select.flickity', function () {
            flkty.flickity('playPlayer');
        });
        // When slider is loaded, hide loader and show slider
        // $('.wrap-carousel .wrap-loader').fadeOut('fast', function () {
        //     $(this).remove();
        //     flkty.addClass('shown');
        // });
    }


    /*
     -- @ HOMEPAGE Cycle Text @ --
        * Used in:
            - Homepage
            */
    if ($('.homepage-cycletext span').length) {

        var
            $container = $('.homepage-cycletext'),
            $span = $container.find('span'),
            $spanCount = $container.find('span').length,
            index = 0;


        // Show First item, when homepage is loaded
        $container.find('span:eq(0)').addClass('shown');

        // Text Animation
        var $timer = setInterval(function () {
            index++;

            // If index = nb span, set to 0
            if (index == $spanCount) index = 0;

            $container.find('span').removeClass('shown');
            $container.find('span:eq(' + index + ')').addClass('shown');
        }, 4500);
    }


    /*
     -- @ HEADER : bind scroll event @ --
        - change Header bg color
        - "go-down" opacity
        */
    function fireAction(pos) {
        if (pos < 100) {
            $('#main-header').removeClass('sticky');
        } else {
            $('#main-header').addClass('sticky');
        }

        // Change "go-down" opacity on homepage
        $('.homepage .go-down').css('opacity', 1 - pos / 400);
    }

    var
        last_known_position = 0,
        ticking = false;

    window.addEventListener('scroll', function (e) {
        last_known_position = window.scrollY;

        if (!ticking) {
            window.requestAnimationFrame(function () {
                fireAction(last_known_position);
                ticking = false;
            });
        }

        ticking = true;
    });
    // Launch fireAction when page is loaded
    fireAction(window.scrollY);


    /*!
        -- @ Modals @ --
    */
    /* Open modal */
    $(document).on('click', '[data-modal]', function (e) {
        e.preventDefault();

        var
            modalId = $(this).data('modal'),
            $modal = $('#' + modalId);

        if ($modal.length) {

            $('html,body').addClass('opened-modal');
            $modal.addClass('active');

            // Callback
            $modal.trigger('cc.modal.open');
        } else {
            alert('ChuckCSS error : modal with attribute id="' + modalId + '" does not exist!');
        }
    });

    /* Close modal */
    $(document).on('click', '.modal:not([data-disabled-overlay]) .modal-overlay, .modal *[data-close-modal]', function (e) {
        e.preventDefault();

        var $modal = $(this).parents('.modal');


        if ($modal.hasClass('active'))
            $modal.removeClass('active');

        if (!$('.modal.active').length)
            $('html,body').removeClass('opened-modal');

        // Callback
        $modal.trigger('cc.modal.close');
    });


    /* Modal callback */
    /*
        EXEMPLES ONLY
        -- Bind your own modal callback by changing the selector
        */
    $('#modal-contact')
        .on('cc.modal.open', function () {
            _closeMenus();
        })
        .on('cc.modal.close', function () {
            // Do something...
        });
});
/*
    * end/ jQuery Plugins
*/